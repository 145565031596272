<template>
  <div class="automated-main">
    <div class="automated-payments">
      <h1>Customize E-Store for event tickets, merch, and experiences</h1>

      <div class="automated-payments-info">
        <div class="automated-payments-left-part">
          <li class="first-list-item">
            <p>
              Easily create a website with design, products, and pricing fully
              customized.
            </p>
          </li>
          <li class="first-list-item">
            <span class="wordings">
              Share the website via social media and chat apps.
            </span>
          </li>
          <li class="first-list-item">
            <p>Designed for selling tickets, merch, and booking experiences.</p>
          </li>
          <li class="first-list-item">
            <p>Optimized for both mobile and desktop display.</p>
          </li>
        </div>

        <div class="automated-payments-right-part">
          <img
            v-motion
            src="img/customized-e-store.png"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.to-hero {
  margin: 0;
}

.hero {
  background-color: #f3f7f8;
}

.automated-main {
  width: 100%;
  background-color: #f3f7f8;
}

.automated-payments {
  width: 71rem;
  margin: auto;
  padding-top: 6rem;
  border-left: 2px solid white;
}

.automated-payments h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 3rem;
  padding-left: 30px;
  color: #25a4e1;
}

.automated-payments h1 {
  line-height: 1.5;
  width: 60%;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.wordings {
  font-weight: 600;
  color: #25a4e1;
}

.automated-payments-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  margin: auto;
  margin-top: 2rem;
  padding-bottom: 3.5rem;
  width: 95%;
  align-items: center;
  justify-content: center;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.automated-payments-left-part li {
  font-size: 1rem;
  top: -10rem;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 15%;
  background-size: 1rem;
}

.automated-payments-left-part p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.automated-payments-left-part,
.automated-payments-right-part {
  width: 100%;
}

.automated-payments-right-part img {
  width: 80%;
  height: auto;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .automated-payments {
    width: 60rem;
  }

  .automated-payments-info {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  .automated-payments {
    width: 40rem;
  }

  .automated-payments-info {
    grid-template-columns: 1fr;
  }

  .automated-payments h1 {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .automated-payments {
    width: 90%;
    border-left: none;
  }

  .automated-payments-info {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .automated-payments h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .automated-payments h1 {
    font-size: 1.5rem;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .automated-payments-left-part li {
    background-position: 0% 3%;
  }

  .automated-payments-info img {
    width: 90%;
    margin-top: 2rem;
  }

  .banks {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
